import PropTypes from 'prop-types';

import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const SpotahomeFooter = ({ linkTheme }) => {
  const spotahomeLinks = [
    {
      url: getPagePath('about-us'),
      transKey: 'footer.item.about_us'
    },
    {
      url: getPagePath('externals.blog'),
      transKey: 'footer.item.blog'
    },
    {
      url: getPagePath('institutions-list'),
      transKey: 'footer.item.institutions'
    },
    {
      url: getPagePath('landings.company-details'),
      transKey: 'footer.item.company_details',
      noFollow: true
    },
    {
      url: getPagePath('sitemap-search'),
      transKey: 'footer.item.sitemap'
    },
    {
      url: getPagePath('postdoctorate-grants'),
      transKey: 'footer.item.postdoctorate-grants'
    }
  ];

  return (
    <FooterBlock>
      <FooterBlockTitle text="Spotahome" />
      <FooterBlockList>
        {spotahomeLinks
          .filter(({ url }) => !!url)
          .map(({ transKey, url, noFollow }) => (
            <FooterLink
              key={transKey}
              linkTheme={linkTheme}
              href={url}
              rel={noFollow ? 'nofollow' : ''}
            >
              {trans(transKey)}
            </FooterLink>
          ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

SpotahomeFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default SpotahomeFooter;
