import { trans } from '@spotahome/soyuz/client';

import { LANDING_SECTIONS, PUBLIC_ASSETS_LINK } from '../utils/seoConstants';

import withInViewEvent from '../withInViewEvent';

import styles from './OurGuarantee.module.scss';

const OUR_GUARANTEE = [
  {
    title: 'our-guarantee.1.title',
    text: 'our-guarantee.1.text',
    icon: 'bell'
  },
  {
    title: 'our-guarantee.2.title',
    text: 'our-guarantee.2.text',
    icon: 'clock'
  },
  {
    title: 'our-guarantee.3.title',
    text: 'our-guarantee.3.text',
    icon: 'medal'
  },
  {
    title: 'our-guarantee.4.title',
    text: 'our-guarantee.4.text',
    icon: 'inlove'
  }
];
const OurGuarantee = () => {
  return (
    <section id="our-guarantee" className={styles['our-guarantee']}>
      <h3 className={styles['our-guarantee__title']}>
        {trans('our-guarantee.title')}
      </h3>
      <div className={styles['our-guarantee__points']}>
        {OUR_GUARANTEE.map(({ title, text, icon }) => (
          <div className={styles['our-guarantee__point']} key={title}>
            <img src={`${PUBLIC_ASSETS_LINK}${icon}.svg`} alt={trans(title)} />
            <div className={styles['our-guarantee__point-title']}>
              {trans(title)}
            </div>
            <div
              className={styles['our-guarantee__point-text']}
              dangerouslySetInnerHTML={{ __html: trans(text) }}
            />
          </div>
        ))}
      </div>
      {/** CTA hidden until guarantees landing is ready **/}
      {/* <Button className={styles["our-guarantee__cta"]}>
        {trans('our-guarantee.cta')}
      </Button> */}
    </section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.ourGuarantee)(OurGuarantee);
