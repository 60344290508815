import PropTypes from 'prop-types';

import { HeaderContainer as HeaderContainerComponent } from '@spotahome/ui-library';

const HeaderContainer = ({
  color = 'dark',
  tracking = '',
  links = {},
  transparent = true,
  ...restProps
}) => {
  return (
    <HeaderContainerComponent
      transparent={transparent}
      color={color}
      showLogin
      isContactUsEnabled
      tracking={tracking}
      links={links}
      {...restProps}
    />
  );
};

HeaderContainer.propTypes = {
  color: PropTypes.string,
  tracking: PropTypes.string,
  links: PropTypes.shape({
    listYourProperty: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({})]),
    tenantLandingPage: PropTypes.oneOf([PropTypes.bool, PropTypes.shape({})])
  }),
  transparent: PropTypes.bool
};

export default HeaderContainer;
