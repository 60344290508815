import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { MediaQuery } from '@spotahome/ui-library';
import classNames from 'classnames';

import { trans, getPagePath, getResourcePath } from '@spotahome/soyuz/client';

import { TOP_CITIES } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

// styles
import './CityRedesign.scss';

const CityRedesign = props => {
  const { id } = props;
  const cityName = trans(`city.${id}`);

  const path = getPagePath('marketplace.free-search', {
    params: { locationString: id }
  });

  const { isSmallerThanLaptop } = MediaQuery;

  const cityImageClassnames = classNames('city-redesign-image', {
    'city-redesign-image__small-display': isSmallerThanLaptop()
  });

  return (
    <a href={path}>
      <div className={`city-redesign ga-home-city-list-${id}`}>
        <LazyLoad height={200} offset={[150, 150]} once>
          <figure className={cityImageClassnames}>
            <img
              src={getResourcePath(`/images/home/city-list-redesign/${id}.jpg`)}
              alt={cityName}
            />
          </figure>
        </LazyLoad>

        <div className="city-redesign-count">
          <h3 className="city-redesign-info-name">{cityName}</h3>
          <span className="city-redesign-info-homes">
            +{`${TOP_CITIES[id]} ${trans('landingpage.label.properties')}`}
          </span>
        </div>
      </div>
    </a>
  );
};

CityRedesign.propTypes = {
  id: PropTypes.string.isRequired
};
export default CityRedesign;
