import PropTypes from 'prop-types';
import { trans, getPagePath } from '@spotahome/soyuz/client';

import classNames from 'classnames';

import withInViewEvent from '../withInViewEvent';

import { LANDING_SECTIONS } from '../utils/seoConstants';

import Link from '../Link';

import FAQAccordion from './FAQAccordion';

import styles from './FAQSection.module.scss';

const FAQSection = ({ questions, isRedesign = false }) => {
  const sectionClasses = classNames(styles['faq-section'], {
    [styles['faq-section--redesign']]: isRedesign
  });
  const accordionWrapperClasses = classNames(
    styles['faq-section__accordion-wrapper'],
    {
      [styles['faq-section__accordion-wrapper--redesign']]: isRedesign
    }
  );

  const titleKey = isRedesign
    ? 'home.tenant.FAQ-section.title.still.not.clear'
    : 'home.tenant.FAQ-section.title';

  const titleClasses = classNames(styles['faq-section__title'], {
    [styles['faq-section__title--redesign']]: isRedesign
  });

  return (
    <section className={sectionClasses}>
      <h3 className={titleClasses}>{trans(titleKey)}</h3>
      <div className={accordionWrapperClasses}>
        <FAQAccordion
          data={questions}
          theme={FAQAccordion.themes.tenant}
          isRedesign={isRedesign}
        />
      </div>
      <div className={styles['faq-section__bottom']}>
        {`${trans('home.tenant.FAQ-section.footer.title')} `}
        <Link
          href={getPagePath('externals.help-center')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trans('home.tenant.FAQ-section.footer.link')}
        </Link>
      </div>
    </section>
  );
};

FAQSection.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      isOpened: PropTypes.bool
    })
  ).isRequired,
  isRedesign: PropTypes.bool
};

export default withInViewEvent(LANDING_SECTIONS.faq)(FAQSection);
